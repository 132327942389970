import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addTransaction } from '../../store/slices/financeSlice';

const categories = [
  'Salary',
  'Freelance',
  'Business',
  'Investments',
  'Rental',
  'Other'
];

const AddIncome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [income, setIncome] = useState({
    amount: '',
    category: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    type: 'income' // Always 'income' for this component
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await dispatch(addTransaction({
        ...income,
        amount: parseFloat(income.amount)
      })).unwrap();
      
      // Show success message or toast here
      navigate('/finance'); // Redirect to finance dashboard
    } catch (err) {
      setError('Failed to add income. Please try again.');
      console.error('Error adding income:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h1 className="heading-lg mb-6">Add Income</h1>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
          {error}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <div className="relative">
            <span className="absolute left-3 top-2 text-gray-500">$</span>
            <input
              type="number"
              id="amount"
              className="input pl-7"
              placeholder="0.00"
              step="0.01"
              required
              value={income.amount}
              onChange={(e) => setIncome({ ...income, amount: e.target.value })}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            className="input"
            required
            value={income.category}
            onChange={(e) => setIncome({ ...income, category: e.target.value })}
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            className="input"
            placeholder="Enter description"
            required
            value={income.description}
            onChange={(e) => setIncome({ ...income, description: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            className="input"
            required
            value={income.date}
            onChange={(e) => setIncome({ ...income, date: e.target.value })}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary w-full"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Adding Income...' : 'Add Income'}
        </button>
      </form>
    </div>
  );
};

export default AddIncome; 