import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

// Async thunks
export const fetchTimeLogs = createAsyncThunk(
  'timeTracking/fetchTimeLogs',
  async () => {
    const querySnapshot = await getDocs(collection(db, 'timeLogs'));
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  }
);

export const startTimeLog = createAsyncThunk(
  'timeTracking/startTimeLog',
  async (timeLog) => {
    const docRef = await addDoc(collection(db, 'timeLogs'), {
      ...timeLog,
      startTime: new Date().toISOString(),
      isRunning: true
    });
    return { 
      id: docRef.id, 
      ...timeLog, 
      startTime: new Date().toISOString(),
      isRunning: true 
    };
  }
);

export const stopTimeLog = createAsyncThunk(
  'timeTracking/stopTimeLog',
  async ({ id }) => {
    const endTime = new Date().toISOString();
    await updateDoc(doc(db, 'timeLogs', id), {
      endTime,
      isRunning: false
    });
    return { id, endTime };
  }
);

export const addManualTimeLog = createAsyncThunk(
  'timeTracking/addManualTimeLog',
  async (timeLog) => {
    const docRef = await addDoc(collection(db, 'timeLogs'), {
      ...timeLog,
      isRunning: false
    });
    return { id: docRef.id, ...timeLog, isRunning: false };
  }
);

export const deleteTimeLog = createAsyncThunk(
  'timeTracking/deleteTimeLog',
  async (id) => {
    await deleteDoc(doc(db, 'timeLogs', id));
    return id;
  }
);

const timeTrackingSlice = createSlice({
  name: 'timeTracking',
  initialState: {
    logs: [],
    activeLog: null,
    status: 'idle',
    error: null,
    dailySummary: {}
  },
  reducers: {
    updateDailySummary: (state) => {
      const summary = state.logs.reduce((acc, log) => {
        const date = new Date(log.startTime).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = {
            totalTime: 0,
            activities: {}
          };
        }
        
        const duration = log.endTime 
          ? new Date(log.endTime) - new Date(log.startTime)
          : log.isRunning 
            ? new Date() - new Date(log.startTime)
            : 0;
            
        acc[date].totalTime += duration;
        
        if (!acc[date].activities[log.activity]) {
          acc[date].activities[log.activity] = 0;
        }
        acc[date].activities[log.activity] += duration;
        
        return acc;
      }, {});
      
      state.dailySummary = summary;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeLogs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTimeLogs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.logs = action.payload;
        state.activeLog = state.logs.find(log => log.isRunning);
      })
      .addCase(fetchTimeLogs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(startTimeLog.fulfilled, (state, action) => {
        state.logs.unshift(action.payload);
        state.activeLog = action.payload;
      })
      .addCase(stopTimeLog.fulfilled, (state, action) => {
        const index = state.logs.findIndex(log => log.id === action.payload.id);
        if (index !== -1) {
          state.logs[index] = {
            ...state.logs[index],
            endTime: action.payload.endTime,
            isRunning: false
          };
        }
        state.activeLog = null;
      })
      .addCase(addManualTimeLog.fulfilled, (state, action) => {
        state.logs.unshift(action.payload);
      })
      .addCase(deleteTimeLog.fulfilled, (state, action) => {
        state.logs = state.logs.filter(log => log.id !== action.payload);
        if (state.activeLog?.id === action.payload) {
          state.activeLog = null;
        }
      });
  }
});

export const { updateDailySummary } = timeTrackingSlice.actions;
export default timeTrackingSlice.reducer; 