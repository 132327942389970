import React from 'react';

const SettingSection = ({ title, children }) => (
  <div className="bg-white shadow-sm rounded-lg overflow-hidden">
    <div className="p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">{title}</h2>
      {children}
    </div>
  </div>
);

const Settings = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>

      <SettingSection title="Profile">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input type="text" className="input mt-1" placeholder="Your name" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" className="input mt-1" placeholder="your@email.com" />
          </div>
          <button className="btn btn-primary">Save Profile</button>
        </div>
      </SettingSection>

      <SettingSection title="Preferences">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Email Notifications</h3>
              <p className="text-sm text-gray-500">Receive email notifications for important updates</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Dark Mode</h3>
              <p className="text-sm text-gray-500">Use dark theme across the application</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </SettingSection>

      <SettingSection title="Data Management">
        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium text-gray-900">Export Data</h3>
            <p className="text-sm text-gray-500 mb-2">Download all your data in JSON format</p>
            <button className="btn btn-secondary">Export All Data</button>
          </div>
          <div>
            <h3 className="text-sm font-medium text-red-600">Danger Zone</h3>
            <p className="text-sm text-gray-500 mb-2">Delete all your data permanently</p>
            <button className="btn bg-red-600 text-white hover:bg-red-700">Delete All Data</button>
          </div>
        </div>
      </SettingSection>
    </div>
  );
};

export default Settings; 