import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';

// Layout Components
import Navbar from './components/layout/Navbar';
import Sidebar from './components/layout/Sidebar';
import BottomNav from './components/layout/BottomNav';

// Pages
import Dashboard from './pages/Dashboard';
import Notes from './pages/Notes';
import Finance from './pages/Finance';
import TimeTracking from './pages/TimeTracking';
import Settings from './pages/Settings';

// Finance Pages
import AddExpense from './pages/finance/AddExpense';
import AddIncome from './pages/finance/AddIncome';
import MonthlySummary from './pages/finance/MonthlySummary';
import Analytics from './pages/finance/Analytics';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <Navbar onMenuClick={toggleSidebar} />
          <div className="flex flex-1">
            <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
            <main className="flex-1 p-4 md:p-6 pb-20 md:pb-6 overflow-x-hidden">
              <div className="max-w-7xl mx-auto">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/finance" element={<Finance />} />
                  <Route path="/finance/add-expense" element={<AddExpense />} />
                  <Route path="/finance/add-income" element={<AddIncome />} />
                  <Route path="/finance/summary" element={<MonthlySummary />} />
                  <Route path="/finance/analytics" element={<Analytics />} />
                  <Route path="/time-tracking" element={<TimeTracking />} />
                  <Route path="/settings" element={<Settings />} />
                </Routes>
              </div>
            </main>
          </div>
          <BottomNav />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
