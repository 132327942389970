import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchNotes } from '../store/slices/notesSlice';
import { fetchTransactions, updateSummary } from '../store/slices/financeSlice';
import { fetchTimeLogs, updateDailySummary } from '../store/slices/timeTrackingSlice';

const SummaryCard = ({ title, value, description, icon, linkTo }) => (
  <Link to={linkTo} className="block">
    <div className="bg-white overflow-hidden shadow rounded-lg transition-shadow hover:shadow-md">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
              {icon}
            </div>
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {title}
              </dt>
              <dd className="flex items-baseline">
                <div className="text-2xl font-semibold text-gray-900">
                  {value}
                </div>
              </dd>
              <dd className="text-sm text-gray-500">
                {description}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.items);
  const { summary, status } = useSelector((state) => state.finance);
  const timeTracking = useSelector((state) => state.timeTracking);

  useEffect(() => {
    dispatch(fetchNotes());
    dispatch(fetchTransactions());
    dispatch(fetchTimeLogs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateSummary());
    dispatch(updateDailySummary());
  }, [dispatch]);

  const today = new Date().toLocaleDateString();
  const todayTimeLog = timeTracking.dailySummary[today] || { totalTime: 0 };
  const formatTime = (ms) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <SummaryCard
          title="Notes"
          value={notes.length}
          description="Total notes"
          linkTo="/notes"
          icon={
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          }
        />

        <SummaryCard
          title="Balance"
          value={status === 'loading' ? 'Loading...' : `$${summary.balance.toFixed(2)}`}
          description={
            status === 'loading'
              ? 'Loading financial data...'
              : `Income: $${summary.income.toFixed(2)} | Expenses: $${summary.expenses.toFixed(2)}`
          }
          linkTo="/finance"
          icon={
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          }
        />

        <SummaryCard
          title="Today's Time Log"
          value={formatTime(todayTimeLog.totalTime)}
          description="Time tracked today"
          linkTo="/time-tracking"
          icon={
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          }
        />
      </div>

      {timeTracking.activeLog && (
        <div className="mt-6 bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg className="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">
                Timer Running
              </h3>
              <div className="mt-1 text-sm text-blue-700">
                {timeTracking.activeLog.activity} - Started at {new Date(timeTracking.activeLog.startTime).toLocaleTimeString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard; 