import React from 'react';
import { NavLink } from 'react-router-dom';

const BottomNav = () => {
  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:hidden z-50">
      <div className="grid grid-cols-4 h-16">
        <NavLink
          to="/finance/add-expense"
          className={({ isActive }) => `
            flex flex-col items-center justify-center text-xs
            ${isActive ? 'text-blue-600' : 'text-gray-600'}
          `}
        >
          <svg className="w-6 h-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4M12 20V4" />
          </svg>
          <span>Expense</span>
        </NavLink>

        <NavLink
          to="/finance/add-income"
          className={({ isActive }) => `
            flex flex-col items-center justify-center text-xs
            ${isActive ? 'text-blue-600' : 'text-gray-600'}
          `}
        >
          <svg className="w-6 h-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v12m-8-6h16" />
          </svg>
          <span>Income</span>
        </NavLink>

        <NavLink
          to="/finance/summary"
          className={({ isActive }) => `
            flex flex-col items-center justify-center text-xs
            ${isActive ? 'text-blue-600' : 'text-gray-600'}
          `}
        >
          <svg className="w-6 h-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
          </svg>
          <span>Summary</span>
        </NavLink>

        <NavLink
          to="/finance/analytics"
          className={({ isActive }) => `
            flex flex-col items-center justify-center text-xs
            ${isActive ? 'text-blue-600' : 'text-gray-600'}
          `}
        >
          <svg className="w-6 h-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span>Analytics</span>
        </NavLink>
      </div>
    </nav>
  );
};

export default BottomNav; 