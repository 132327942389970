import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

// Async thunks
export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async () => {
    const querySnapshot = await getDocs(collection(db, 'notes'));
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  }
);

export const createNote = createAsyncThunk(
  'notes/createNote',
  async (note) => {
    const docRef = await addDoc(collection(db, 'notes'), note);
    return { id: docRef.id, ...note };
  }
);

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ id, note }) => {
    await updateDoc(doc(db, 'notes', id), note);
    return { id, ...note };
  }
);

export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (id) => {
    await deleteDoc(doc(db, 'notes', id));
    return id;
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    items: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const index = state.items.findIndex(note => note.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.items = state.items.filter(note => note.id !== action.payload);
      });
  }
});

export default notesSlice.reducer; 