import { db } from '../firebase';
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  getDocs,
  Timestamp,
  runTransaction
} from 'firebase/firestore';

const TRANSACTIONS_COLLECTION = 'transactions';
const ACCOUNT_BALANCES_COLLECTION = 'accountBalances';

export const financeService = {
  // Fetch all transactions
  async fetchTransactions() {
    try {
      const q = query(
        collection(db, TRANSACTIONS_COLLECTION),
        orderBy('timestamp', 'desc')
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate().toISOString()
      }));
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  },

  // Fetch account balances
  async fetchAccountBalances() {
    try {
      const balancesRef = collection(db, ACCOUNT_BALANCES_COLLECTION);
      const snapshot = await getDocs(balancesRef);
      const accounts = {};
      
      snapshot.forEach(doc => {
        const data = doc.data();
        accounts[doc.id] = {
          balance: data.balance || 0,
          type: data.type,
          tags: data.tags || [],
          description: data.description || '',
          lastUpdated: data.lastUpdated
        };
      });
      
      return accounts;
    } catch (error) {
      console.error('Error fetching account balances:', error);
      throw error;
    }
  },

  // Add a new transaction
  async addTransaction(transactionData) {
    try {
      return await runTransaction(db, async (transaction) => {
        // Add the transaction
        const transactionRef = doc(collection(db, TRANSACTIONS_COLLECTION));
        const newTransaction = {
          ...transactionData,
          timestamp: Timestamp.now(),
          createdAt: Timestamp.now()
        };
        
        transaction.set(transactionRef, newTransaction);

        // Update account balance
        const balanceRef = doc(db, ACCOUNT_BALANCES_COLLECTION, transactionData.method);
        const balanceDoc = await transaction.get(balanceRef);
        const currentBalance = balanceDoc.data().balance;
        
        const balanceChange = transactionData.type === 'income' 
          ? parseFloat(transactionData.amount) 
          : -parseFloat(transactionData.amount);
        
        transaction.update(balanceRef, {
          balance: currentBalance + balanceChange,
          lastUpdated: Timestamp.now()
        });

        return {
          id: transactionRef.id,
          ...transactionData,
          timestamp: new Date().toISOString()
        };
      });
    } catch (error) {
      console.error('Error adding transaction:', error);
      throw error;
    }
  },

  // Update an existing transaction
  async updateTransaction(id, newTransactionData) {
    try {
      return await runTransaction(db, async (transaction) => {
        // Get the old transaction
        const transactionRef = doc(db, TRANSACTIONS_COLLECTION, id);
        const oldTransactionDoc = await transaction.get(transactionRef);
        const oldTransaction = oldTransactionDoc.data();

        // Update the transaction
        const updatedTransaction = {
          ...newTransactionData,
          updatedAt: Timestamp.now()
        };
        transaction.update(transactionRef, updatedTransaction);

        // If the payment method changed, update both old and new account balances
        if (oldTransaction.method !== newTransactionData.method) {
          // Revert the old account balance
          const oldBalanceRef = doc(db, ACCOUNT_BALANCES_COLLECTION, oldTransaction.method);
          const oldBalanceDoc = await transaction.get(oldBalanceRef);
          const oldBalance = oldBalanceDoc.data().balance;
          const oldBalanceChange = oldTransaction.type === 'income' 
            ? -parseFloat(oldTransaction.amount) 
            : parseFloat(oldTransaction.amount);
          
          transaction.update(oldBalanceRef, {
            balance: oldBalance + oldBalanceChange,
            lastUpdated: Timestamp.now()
          });

          // Update the new account balance
          const newBalanceRef = doc(db, ACCOUNT_BALANCES_COLLECTION, newTransactionData.method);
          const newBalanceDoc = await transaction.get(newBalanceRef);
          const newBalance = newBalanceDoc.data().balance;
          const newBalanceChange = newTransactionData.type === 'income' 
            ? parseFloat(newTransactionData.amount) 
            : -parseFloat(newTransactionData.amount);
          
          transaction.update(newBalanceRef, {
            balance: newBalance + newBalanceChange,
            lastUpdated: Timestamp.now()
          });
        } else if (oldTransaction.amount !== newTransactionData.amount || 
                   oldTransaction.type !== newTransactionData.type) {
          // If only amount or type changed, update the same account balance
          const balanceRef = doc(db, ACCOUNT_BALANCES_COLLECTION, newTransactionData.method);
          const balanceDoc = await transaction.get(balanceRef);
          const currentBalance = balanceDoc.data().balance;

          // Revert old transaction
          const oldBalanceChange = oldTransaction.type === 'income' 
            ? -parseFloat(oldTransaction.amount) 
            : parseFloat(oldTransaction.amount);
          
          // Apply new transaction
          const newBalanceChange = newTransactionData.type === 'income' 
            ? parseFloat(newTransactionData.amount) 
            : -parseFloat(newTransactionData.amount);

          transaction.update(balanceRef, {
            balance: currentBalance + oldBalanceChange + newBalanceChange,
            lastUpdated: Timestamp.now()
          });
        }

        return {
          id,
          ...newTransactionData,
          timestamp: oldTransaction.timestamp.toDate().toISOString()
        };
      });
    } catch (error) {
      console.error('Error updating transaction:', error);
      throw error;
    }
  },

  // Delete a transaction
  async deleteTransaction(id) {
    try {
      return await runTransaction(db, async (transaction) => {
        // Get the transaction to be deleted
        const transactionRef = doc(db, TRANSACTIONS_COLLECTION, id);
        const transactionDoc = await transaction.get(transactionRef);
        const transactionData = transactionDoc.data();

        // Delete the transaction
        transaction.delete(transactionRef);

        // Update account balance
        const balanceRef = doc(db, ACCOUNT_BALANCES_COLLECTION, transactionData.method);
        const balanceDoc = await transaction.get(balanceRef);
        const currentBalance = balanceDoc.data().balance;
        
        const balanceChange = transactionData.type === 'income' 
          ? -parseFloat(transactionData.amount) 
          : parseFloat(transactionData.amount);
        
        transaction.update(balanceRef, {
          balance: currentBalance + balanceChange,
          lastUpdated: Timestamp.now()
        });

        return id;
      });
    } catch (error) {
      console.error('Error deleting transaction:', error);
      throw error;
    }
  }
}; 