import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import {
  fetchTransactions,
  addTransaction,
  updateTransaction,
  deleteTransaction,
  updateSummary,
  fetchAccountBalances,
  fetchTransactionMethods,
  addTransactionMethod,
  updateTransactionMethod
} from '../store/slices/financeSlice';
import { Link } from 'react-router-dom';
import { METHOD_TYPES, ACCOUNT_TAGS, configService } from '../services/configService';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const TransactionForm = ({ onSubmit, initialValues = { type: 'expense', amount: '', category: '', description: '', method: '' }, isEditing }) => {
  const dispatch = useDispatch();
  const transactionMethods = useSelector(state => state.finance.transactionMethods);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    dispatch(fetchTransactionMethods());
  }, [dispatch]);

  const categories = {
    expense: ['Food & Dining', 'Transportation', 'Housing', 'Entertainment', 'Bills & Utilities', 'Health', 'Travel', 'Education', 'Other'],
    income: ['Salary', 'Freelance', 'Investment', 'Business', 'Rental', 'Other']
  };

  const activeMethods = Object.entries(transactionMethods)
    .filter(([_, method]) => method.isActive)
    .reduce((acc, [key, method]) => {
      acc[key] = method.name;
      return acc;
    }, {});

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...values,
      amount: parseFloat(values.amount)
    });
    if (!isEditing) {
      setValues({ type: 'expense', amount: '', category: '', description: '', method: '' });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Type</label>
          <select
            value={values.type}
            onChange={(e) => setValues({ ...values, type: e.target.value, category: '' })}
            className="input mt-1"
          >
            <option value="expense">Expense</option>
            <option value="income">Income</option>
          </select>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700">Amount</label>
          <input
            type="number"
            step="0.01"
            value={values.amount}
            onChange={(e) => setValues({ ...values, amount: e.target.value })}
            className="input mt-1"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Category</label>
          <select
            value={values.category}
            onChange={(e) => setValues({ ...values, category: e.target.value })}
            className="input mt-1"
            required
          >
            <option value="">Select a category</option>
            {categories[values.type].map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Payment Method</label>
          <select
            value={values.method}
            onChange={(e) => setValues({ ...values, method: e.target.value })}
            className="input mt-1"
            required
          >
            <option value="">Select payment method</option>
            {Object.entries(activeMethods).map(([key, name]) => (
              <option key={key} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <input
          type="text"
          value={values.description}
          onChange={(e) => setValues({ ...values, description: e.target.value })}
          className="input mt-1"
          required
        />
      </div>

      <button type="submit" className="btn btn-primary">
        {isEditing ? 'Update Transaction' : 'Add Transaction'}
      </button>
    </form>
  );
};

const TransactionList = ({ transactions, onEdit, onDelete }) => {
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const filteredTransactions = transactions.filter(transaction => {
    if (selectedAccount && transaction.method !== selectedAccount) return false;
    if (selectedType && transaction.type !== selectedType) return false;
    if (selectedCategory && transaction.category !== selectedCategory) return false;
    return true;
  });

  const categories = {
    expense: ['Food & Dining', 'Transportation', 'Housing', 'Entertainment', 'Bills & Utilities', 'Health', 'Travel', 'Education', 'Other'],
    income: ['Salary', 'Freelance', 'Investment', 'Business', 'Rental', 'Other']
  };

  const allCategories = [...new Set([...categories.expense, ...categories.income])];

  return (
    <div className="overflow-x-auto">
      <div className="mb-4 flex gap-4">
        <select
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          className="input"
        >
          <option value="">All Accounts</option>
          {Object.values(METHOD_TYPES).map(method => (
            <option key={method} value={method}>{method}</option>
          ))}
        </select>

        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="input"
        >
          <option value="">All Types</option>
          <option value="income">Income</option>
          <option value="expense">Expense</option>
        </select>

        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="input"
        >
          <option value="">All Categories</option>
          {allCategories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Account</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredTransactions.map((transaction) => (
            <tr key={transaction.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(transaction.timestamp).toLocaleDateString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  transaction.type === 'income' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {transaction.type}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.category}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.description}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.method}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'BDT'
                }).format(transaction.amount)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  onClick={() => onEdit(transaction)}
                  className="text-indigo-600 hover:text-indigo-900 mr-4"
                >
                  Edit
                </button>
                <button
                  onClick={() => onDelete(transaction.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const quickActions = [
  {
    name: 'Add Expense',
    path: '/finance/add-expense',
    icon: (
      <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4M12 20V4" />
      </svg>
    ),
    color: 'bg-red-50 text-red-700',
  },
  {
    name: 'Add Income',
    path: '/finance/add-income',
    icon: (
      <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v12m-8-6h16" />
      </svg>
    ),
    color: 'bg-green-50 text-green-700',
  },
  {
    name: 'Monthly Summary',
    path: '/finance/summary',
    icon: (
      <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    ),
    color: 'bg-blue-50 text-blue-700',
  },
  {
    name: 'Analytics',
    path: '/finance/analytics',
    icon: (
      <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
    ),
    color: 'bg-purple-50 text-purple-700',
  },
];

const MethodManagement = () => {
  const dispatch = useDispatch();
  const transactionMethods = useSelector(state => state.finance.transactionMethods);
  const [newMethodName, setNewMethodName] = useState('');
  const [newMethodType, setNewMethodType] = useState('');
  const [newMethodCategory, setNewMethodCategory] = useState('');
  const [error, setError] = useState('');
  const [editingMethod, setEditingMethod] = useState(null);

  const methodCategories = {
    'bank': ['Traditional Bank', 'Digital Bank', 'International Bank'],
    'mobile': ['Mobile Banking', 'Digital Wallet', 'Payment App'],
    'credit': ['Credit Card', 'Charge Card', 'Store Card'],
    'cash': ['Physical Cash', 'Petty Cash', 'Safe']
  };

  const validateMethodName = (name) => {
    // Check for duplicates
    const exists = Object.values(transactionMethods).some(
      method => method.name.toLowerCase() === name.toLowerCase()
    );
    if (exists) {
      setError('A method with this name already exists');
      return false;
    }
    // Basic validation
    if (name.length < 2) {
      setError('Method name must be at least 2 characters long');
      return false;
    }
    setError('');
    return true;
  };

  const handleAddMethod = async (e) => {
    e.preventDefault();
    if (!validateMethodName(newMethodName)) return;

    try {
      await dispatch(addTransactionMethod({
        name: newMethodName,
        type: newMethodType,
        category: newMethodCategory
      })).unwrap();
      
      setNewMethodName('');
      setNewMethodType('');
      setNewMethodCategory('');
      setError('');
    } catch (err) {
      setError('Failed to add method: ' + err.message);
    }
  };

  const handleToggleMethod = async (key, method) => {
    try {
      await dispatch(updateTransactionMethod({
        key,
        data: {
          ...method,
          isActive: !method.isActive
        }
      })).unwrap();
    } catch (err) {
      setError('Failed to update method: ' + err.message);
    }
  };

  const handleUpdateMethod = async (e) => {
    e.preventDefault();
    if (!editingMethod) return;

    try {
      await dispatch(updateTransactionMethod({
        key: editingMethod.key,
        data: {
          name: newMethodName,
          type: newMethodType,
          category: newMethodCategory,
          isActive: editingMethod.isActive
        }
      })).unwrap();
      
      setEditingMethod(null);
      setNewMethodName('');
      setNewMethodType('');
      setNewMethodCategory('');
      setError('');
    } catch (err) {
      setError('Failed to update method: ' + err.message);
    }
  };

  // Group methods by type
  const groupedMethods = Object.entries(transactionMethods).reduce((acc, [key, method]) => {
    if (!acc[method.type]) {
      acc[method.type] = [];
    }
    acc[method.type].push({ key, ...method });
    return acc;
  }, {});

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-4">
          {editingMethod ? 'Edit Payment Method' : 'Add New Payment Method'}
        </h3>
        
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={editingMethod ? handleUpdateMethod : handleAddMethod} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Method Name</label>
            <input
              type="text"
              value={newMethodName}
              onChange={(e) => setNewMethodName(e.target.value)}
              className="input mt-1"
              required
              placeholder="Enter method name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Type</label>
            <select
              value={newMethodType}
              onChange={(e) => {
                setNewMethodType(e.target.value);
                setNewMethodCategory('');
              }}
              className="input mt-1"
              required
            >
              <option value="">Select type</option>
              {Object.keys(METHOD_TYPES).map(type => (
                <option key={type} value={type}>{METHOD_TYPES[type]}</option>
              ))}
            </select>
          </div>

          {newMethodType && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <select
                value={newMethodCategory}
                onChange={(e) => setNewMethodCategory(e.target.value)}
                className="input mt-1"
                required
              >
                <option value="">Select category</option>
                {methodCategories[newMethodType]?.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          )}

          <div className="flex justify-end space-x-3">
            {editingMethod && (
              <button
                type="button"
                onClick={() => {
                  setEditingMethod(null);
                  setNewMethodName('');
                  setNewMethodType('');
                  setNewMethodCategory('');
                  setError('');
                }}
                className="btn btn-secondary"
              >
                Cancel
              </button>
            )}
            <button type="submit" className="btn btn-primary">
              {editingMethod ? 'Update Method' : 'Add Method'}
            </button>
          </div>
        </form>
      </div>

      <div className="bg-white rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-4">Payment Methods</h3>
        
        {Object.entries(groupedMethods).map(([type, methods]) => (
          <div key={type} className="mb-6">
            <h4 className="text-md font-medium text-gray-700 mb-3">
              {METHOD_TYPES[type]}
            </h4>
            <div className="space-y-3">
              {methods.map(method => (
                <div
                  key={method.key}
                  className={`p-4 rounded-lg border ${
                    method.isActive ? 'bg-white' : 'bg-gray-50'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <h5 className="font-medium">{method.name}</h5>
                      <p className="text-sm text-gray-500">{method.category}</p>
                    </div>
                    <div className="flex items-center space-x-3">
                      <button
                        onClick={() => {
                          setEditingMethod(method);
                          setNewMethodName(method.name);
                          setNewMethodType(method.type);
                          setNewMethodCategory(method.category);
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleToggleMethod(method.key, method)}
                        className={`${
                          method.isActive
                            ? 'text-red-600 hover:text-red-800'
                            : 'text-green-600 hover:text-green-800'
                        }`}
                      >
                        {method.isActive ? 'Disable' : 'Enable'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AccountManagement = () => {
  const dispatch = useDispatch();
  const [selectedMethod, setSelectedMethod] = useState('');
  const [initialBalance, setInitialBalance] = useState('');
  const [newBankName, setNewBankName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const transactionMethods = useSelector(state => state.finance.transactionMethods);
  const [activeTab, setActiveTab] = useState('accounts'); // 'accounts' or 'methods'

  useEffect(() => {
    dispatch(fetchTransactionMethods());
  }, [dispatch]);

  const activeMethods = Object.entries(transactionMethods)
    .filter(([_, method]) => method.isActive)
    .reduce((acc, [key, method]) => {
      acc[key] = method.name;
      return acc;
    }, {});

  const handleSetInitialBalance = async (e) => {
    e.preventDefault();
    try {
      await configService.setInitialBalance(selectedMethod, initialBalance);
      dispatch(fetchAccountBalances());
      setInitialBalance('');
      setSelectedMethod('');
      alert('Initial balance set successfully!');
    } catch (error) {
      console.error('Error setting initial balance:', error);
      alert('Failed to set initial balance');
    }
  };

  const handleAddNewBank = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addTransactionMethod({ name: newBankName, type: 'bank' })).unwrap();
      await configService.updateAccountDetails(newBankName, { description, tags: selectedTags });
      dispatch(fetchAccountBalances());
      setNewBankName('');
      setDescription('');
      setSelectedTags([]);
      alert('New bank account added successfully!');
    } catch (error) {
      console.error('Error adding new bank:', error);
      alert('Failed to add new bank account');
    }
  };

  const handleUpdateAccountDetails = async (e) => {
    e.preventDefault();
    try {
      await configService.updateAccountDetails(editingAccount.method, {
        tags: selectedTags,
        description
      });
      dispatch(fetchAccountBalances());
      setEditingAccount(null);
      setDescription('');
      setSelectedTags([]);
      alert('Account details updated successfully!');
    } catch (error) {
      console.error('Error updating account details:', error);
      alert('Failed to update account details');
    }
  };

  const handleEditAccount = (account) => {
    setEditingAccount(account);
    setDescription(account.description);
    setSelectedTags(account.tags);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Account Management</h3>
        <button
          onClick={() => setIsModalOpen(true)}
          className="btn btn-primary btn-sm"
        >
          Manage Accounts
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h4 className="text-lg font-semibold">Account Management</h4>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="mb-6">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex">
                  <button
                    onClick={() => setActiveTab('accounts')}
                    className={`py-2 px-4 border-b-2 font-medium text-sm ${
                      activeTab === 'accounts'
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                  >
                    Accounts
                  </button>
                  <button
                    onClick={() => setActiveTab('methods')}
                    className={`ml-8 py-2 px-4 border-b-2 font-medium text-sm ${
                      activeTab === 'methods'
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                  >
                    Payment Methods
                  </button>
                </nav>
              </div>
            </div>

            {activeTab === 'accounts' ? (
              <div className="space-y-6">
                {/* Existing AccountManagement content */}
                {/* ... existing code ... */}
              </div>
            ) : (
              <MethodManagement />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const AccountBalances = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(state => state.finance.accountBalances);
  const status = useSelector(state => state.finance.status);
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    dispatch(fetchAccountBalances());
  }, [dispatch]);

  if (status === 'loading') {
    return <div>Loading account balances...</div>;
  }

  const filteredAccounts = Object.entries(accounts).filter(([_, account]) => {
    if (selectedTag && !account.tags.includes(selectedTag)) return false;
    if (selectedType && account.type !== selectedType) return false;
    return true;
  });

  const totalBalance = filteredAccounts.reduce((sum, [_, account]) => sum + account.balance, 0);

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Account Balances</h3>
        <div className="flex space-x-4">
          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="input"
          >
            <option value="">All Tags</option>
            {Object.values(ACCOUNT_TAGS).map(tag => (
              <option key={tag} value={tag}>{tag}</option>
            ))}
          </select>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="input"
          >
            <option value="">All Types</option>
            {Object.values(METHOD_TYPES).map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredAccounts.map(([method, account]) => (
          <div key={method} className="bg-gray-50 rounded-lg p-4">
            <div className="flex justify-between items-start mb-2">
              <div>
                <div className="text-sm text-gray-500">{method}</div>
                <div className={`text-lg font-semibold ${account.balance >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'BDT'
                  }).format(account.balance)}
                </div>
              </div>
              <span className="text-xs font-medium bg-gray-200 rounded px-2 py-1">
                {account.type}
              </span>
            </div>
            {account.description && (
              <p className="text-sm text-gray-600 mb-2">{account.description}</p>
            )}
            <div className="flex flex-wrap gap-1">
              {account.tags.map(tag => (
                <span
                  key={tag}
                  className="text-xs bg-indigo-100 text-indigo-800 rounded px-2 py-1"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-4 pt-4 border-t border-gray-200">
        <div className="text-right">
          <span className="text-sm text-gray-600">Total Balance:</span>
          <span className={`ml-2 text-lg font-semibold ${totalBalance >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'BDT'
            }).format(totalBalance)}
          </span>
        </div>
      </div>
    </div>
  );
};

const Finance = () => {
  const dispatch = useDispatch();
  const { transactions, summary, status, error } = useSelector((state) => state.finance);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(fetchTransactions()),
          dispatch(fetchTransactionMethods()),
          dispatch(fetchAccountBalances())
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (transactions.length > 0) {
      dispatch(updateSummary());
    }
  }, [transactions, dispatch]);

  const handleCreateTransaction = async (transactionData) => {
    try {
      await dispatch(addTransaction(transactionData));
    } catch (error) {
      console.error('Error creating transaction:', error);
      alert('Failed to create transaction');
    }
  };

  const handleUpdateTransaction = async (transactionData) => {
    try {
      await dispatch(updateTransaction({
        id: editingTransaction.id,
        transaction: transactionData
      }));
      setEditingTransaction(null);
    } catch (error) {
      console.error('Error updating transaction:', error);
      alert('Failed to update transaction');
    }
  };

  const handleDeleteTransaction = async (id) => {
    if (window.confirm('Are you sure you want to delete this transaction?')) {
      try {
        await dispatch(deleteTransaction(id));
      } catch (error) {
        console.error('Error deleting transaction:', error);
        alert('Failed to delete transaction');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500 mx-auto mb-4"></div>
          <p className="text-gray-600">Loading finance data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center text-red-600">
          <p>Error loading finance data</p>
          <p className="text-sm mt-2">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 btn btn-primary"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // Get current month's data
  const currentMonth = new Date().toISOString().slice(0, 7); // YYYY-MM format
  const currentMonthData = transactions.reduce((acc, transaction) => {
    const transactionMonth = new Date(transaction.timestamp).toISOString().slice(0, 7);
    if (transactionMonth === currentMonth) {
      if (transaction.type === 'income') {
        acc.income += transaction.amount;
      } else {
        acc.expenses += transaction.amount;
      }
    }
    return acc;
  }, { income: 0, expenses: 0 });

  currentMonthData.balance = currentMonthData.income - currentMonthData.expenses;

  // Prepare data for charts
  const monthlyData = transactions.reduce((acc, transaction) => {
    const month = transaction.date.slice(0, 7); // YYYY-MM format
    
    if (!acc[month]) {
      acc[month] = { month, income: 0, expenses: 0 };
    }
    
    if (transaction.type === 'income') {
      acc[month].income += transaction.amount;
    } else {
      acc[month].expenses += transaction.amount;
    }
    
    return acc;
  }, {});

  const chartData = Object.values(monthlyData)
    .sort((a, b) => a.month.localeCompare(b.month))
    .slice(-6); // Last 6 months

  const categoryData = transactions.reduce((acc, transaction) => {
    const key = `${transaction.type}-${transaction.category}`;
    if (!acc[key]) {
      acc[key] = {
        name: `${transaction.category} (${transaction.type})`,
        value: 0
      };
    }
    acc[key].value += transaction.amount;
    return acc;
  }, {});

  const pieData = Object.values(categoryData);

  // Get recent transactions
  const recentTransactions = [...transactions]
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    .slice(0, 3);

  return (
    <div className="space-y-6">
      <h1 className="heading-lg">Finance Dashboard</h1>

      <AccountManagement />
      <AccountBalances />

      {/* Quick Actions */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {quickActions.map((action) => (
          <Link
            key={action.path}
            to={action.path}
            className={`card ${action.color} transition-transform hover:scale-105`}
          >
            <div className="flex flex-col items-center text-center p-4">
              {action.icon}
              <span className="mt-2 font-medium">{action.name}</span>
            </div>
          </Link>
        ))}
      </div>

      {/* Recent Activity */}
      <div className="card">
        <h2 className="heading-sm mb-4">Recent Activity</h2>
        <div className="space-y-4">
          {recentTransactions.map((transaction) => (
            <div key={transaction.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-md">
              <div className="flex flex-col">
                <span className="font-medium">{transaction.description}</span>
                <span className="text-sm text-gray-500">{transaction.category}</span>
              </div>
              <span className={`font-medium ${
                transaction.type === 'income' ? 'text-green-600' : 'text-red-600'
              }`}>
                {transaction.type === 'income' ? '+' : '-'}${transaction.amount.toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Monthly Overview */}
      <div className="grid md:grid-cols-3 gap-4">
        <div className="card bg-green-50">
          <h3 className="text-sm font-medium text-green-800">This Month's Income</h3>
          <p className="text-2xl font-bold text-green-600">${currentMonthData.income.toFixed(2)}</p>
        </div>
        <div className="card bg-red-50">
          <h3 className="text-sm font-medium text-red-800">This Month's Expenses</h3>
          <p className="text-2xl font-bold text-red-600">${currentMonthData.expenses.toFixed(2)}</p>
        </div>
        <div className="card bg-blue-50">
          <h3 className="text-sm font-medium text-blue-800">Net Balance</h3>
          <p className="text-2xl font-bold text-blue-600">${currentMonthData.balance.toFixed(2)}</p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Finance</h1>
        <div className="flex space-x-4">
          <div className="text-right">
            <p className="text-sm text-gray-500">Total Balance</p>
            <p className={`text-2xl font-semibold ${summary.balance >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              ${summary.balance.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Monthly Overview</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="month"
                  tickFormatter={(value) => {
                    const date = new Date(value + '-01');
                    return date.toLocaleDateString(undefined, { month: 'short' });
                  }}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => `$${value.toFixed(2)}`}
                  labelFormatter={(label) => {
                    const date = new Date(label + '-01');
                    return date.toLocaleDateString(undefined, { month: 'long', year: 'numeric' });
                  }}
                />
                <Legend />
                <Line type="monotone" dataKey="income" stroke="#10B981" name="Income" />
                <Line type="monotone" dataKey="expenses" stroke="#EF4444" name="Expenses" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Category Distribution</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, value, percent }) => 
                    `${name} ($${value.toFixed(0)}, ${(percent * 100).toFixed(0)}%)`
                  }
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `$${value.toFixed(2)}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          {editingTransaction ? 'Edit Transaction' : 'Add Transaction'}
        </h2>
        <TransactionForm
          onSubmit={editingTransaction ? handleUpdateTransaction : handleCreateTransaction}
          initialValues={editingTransaction || undefined}
          isEditing={!!editingTransaction}
        />
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Recent Transactions</h2>
        </div>
        <TransactionList
          transactions={transactions}
          onEdit={setEditingTransaction}
          onDelete={handleDeleteTransaction}
        />
      </div>
    </div>
  );
};

export default Finance; 