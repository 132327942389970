import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactions } from '../../store/slices/financeSlice';

const MonthlySummary = () => {
  const dispatch = useDispatch();
  const { transactions, summary, status, error } = useSelector((state) => state.finance);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  // Group transactions by category
  const groupedTransactions = transactions.reduce((acc, transaction) => {
    const category = transaction.category;
    if (!acc[category]) {
      acc[category] = {
        total: 0,
        count: 0,
        type: transaction.type
      };
    }
    acc[category].total += transaction.amount;
    acc[category].count += 1;
    return acc;
  }, {});

  if (status === 'loading') {
    return <div className="text-center py-8">Loading summary...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-600">
        Error loading summary: {error}
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="heading-lg mb-8">Monthly Summary</h1>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="card bg-green-50">
          <h3 className="text-lg font-semibold text-green-700">Total Income</h3>
          <p className="text-2xl font-bold text-green-600">${summary.income.toFixed(2)}</p>
        </div>
        <div className="card bg-red-50">
          <h3 className="text-lg font-semibold text-red-700">Total Expenses</h3>
          <p className="text-2xl font-bold text-red-600">${summary.expenses.toFixed(2)}</p>
        </div>
        <div className="card bg-blue-50">
          <h3 className="text-lg font-semibold text-blue-700">Net Balance</h3>
          <p className="text-2xl font-bold text-blue-600">${summary.balance.toFixed(2)}</p>
        </div>
      </div>

      {/* Category Breakdown */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Income Categories */}
        <div className="card">
          <h2 className="text-xl font-semibold mb-4">Income by Category</h2>
          <div className="space-y-4">
            {Object.entries(groupedTransactions)
              .filter(([_, data]) => data.type === 'income')
              .map(([category, data]) => (
                <div key={category} className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{category}</p>
                    <p className="text-sm text-gray-500">{data.count} transactions</p>
                  </div>
                  <p className="text-green-600 font-semibold">
                    ${data.total.toFixed(2)}
                  </p>
                </div>
              ))}
          </div>
        </div>

        {/* Expense Categories */}
        <div className="card">
          <h2 className="text-xl font-semibold mb-4">Expenses by Category</h2>
          <div className="space-y-4">
            {Object.entries(groupedTransactions)
              .filter(([_, data]) => data.type === 'expense')
              .map(([category, data]) => (
                <div key={category} className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{category}</p>
                    <p className="text-sm text-gray-500">{data.count} transactions</p>
                  </div>
                  <p className="text-red-600 font-semibold">
                    ${data.total.toFixed(2)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Recent Transactions */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>
        <div className="space-y-4">
          {transactions.slice(0, 5).map((transaction) => (
            <div
              key={transaction.id}
              className="card flex justify-between items-center"
            >
              <div>
                <p className="font-medium">{transaction.description}</p>
                <p className="text-sm text-gray-500">
                  {transaction.category} • {new Date(transaction.date).toLocaleDateString()}
                </p>
              </div>
              <p
                className={`font-semibold ${
                  transaction.type === 'income'
                    ? 'text-green-600'
                    : 'text-red-600'
                }`}
              >
                ${transaction.amount.toFixed(2)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MonthlySummary; 