import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { fetchTransactions } from '../../store/slices/financeSlice';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

const Analytics = () => {
  const dispatch = useDispatch();
  const { transactions, status, error } = useSelector((state) => state.finance);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const analytics = useMemo(() => {
    if (!transactions.length) return null;

    // Group transactions by month
    const monthlyData = transactions.reduce((acc, transaction) => {
      const month = transaction.date.slice(0, 7); // YYYY-MM format
      if (!acc[month]) {
        acc[month] = { income: 0, expenses: 0 };
      }
      if (transaction.type === 'income') {
        acc[month].income += transaction.amount;
      } else {
        acc[month].expenses += transaction.amount;
      }
      return acc;
    }, {});

    // Convert to array format for charts
    const monthlyChartData = Object.entries(monthlyData)
      .map(([month, data]) => ({
        month,
        income: data.income,
        expenses: data.expenses,
        balance: data.income - data.expenses
      }))
      .sort((a, b) => a.month.localeCompare(b.month))
      .slice(-6); // Last 6 months

    // Calculate expense distribution
    const expensesByCategory = transactions
      .filter(t => t.type === 'expense')
      .reduce((acc, transaction) => {
        const { category, amount } = transaction;
        acc[category] = (acc[category] || 0) + amount;
        return acc;
      }, {});

    const pieChartData = Object.entries(expensesByCategory).map(([name, value]) => ({
      name,
      value
    }));

    // Calculate key metrics
    const totalIncome = transactions
      .filter(t => t.type === 'income')
      .reduce((sum, t) => sum + t.amount, 0);
    
    const totalExpenses = transactions
      .filter(t => t.type === 'expense')
      .reduce((sum, t) => sum + t.amount, 0);

    const monthCount = Object.keys(monthlyData).length || 1;
    
    return {
      monthlyChartData,
      pieChartData,
      metrics: {
        avgMonthlyIncome: totalIncome / monthCount,
        avgMonthlyExpenses: totalExpenses / monthCount,
        savingsRate: ((totalIncome - totalExpenses) / totalIncome) * 100
      }
    };
  }, [transactions]);

  if (status === 'loading') {
    return <div className="text-center py-8">Loading analytics...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-600">
        Error loading analytics: {error}
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="text-center py-8">
        No transaction data available for analysis.
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="heading-lg mb-8">Financial Analytics</h1>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="card">
          <h3 className="text-lg font-semibold text-gray-700">Avg Monthly Income</h3>
          <p className="text-2xl font-bold text-green-600">
            ${analytics.metrics.avgMonthlyIncome.toFixed(2)}
          </p>
        </div>
        <div className="card">
          <h3 className="text-lg font-semibold text-gray-700">Avg Monthly Expenses</h3>
          <p className="text-2xl font-bold text-red-600">
            ${analytics.metrics.avgMonthlyExpenses.toFixed(2)}
          </p>
        </div>
        <div className="card">
          <h3 className="text-lg font-semibold text-gray-700">Savings Rate</h3>
          <p className="text-2xl font-bold text-blue-600">
            {analytics.metrics.savingsRate.toFixed(1)}%
          </p>
        </div>
      </div>

      {/* Monthly Trends Chart */}
      <div className="card mb-8">
        <h2 className="text-xl font-semibold mb-4">Monthly Trends</h2>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analytics.monthlyChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString(undefined, {
                    month: 'short',
                    year: '2-digit'
                  });
                }}
              />
              <YAxis />
              <Tooltip
                formatter={(value) => `$${value.toFixed(2)}`}
                labelFormatter={(label) => {
                  const date = new Date(label);
                  return date.toLocaleDateString(undefined, {
                    month: 'long',
                    year: 'numeric'
                  });
                }}
              />
              <Legend />
              <Bar dataKey="income" fill="#4CAF50" name="Income" />
              <Bar dataKey="expenses" fill="#f44336" name="Expenses" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Expense Distribution */}
      <div className="card">
        <h2 className="text-xl font-semibold mb-4">Expense Distribution</h2>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={analytics.pieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  name
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      fill="white"
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline="central"
                    >
                      {`${name} ($${value.toFixed(0)})`}
                    </text>
                  );
                }}
              >
                {analytics.pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => `$${value.toFixed(2)}`}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Analytics; 