import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { financeService } from '../../services/financeService';
import { configService } from '../../services/configService';

// Fetch transaction methods
export const fetchTransactionMethods = createAsyncThunk(
  'finance/fetchTransactionMethods',
  async () => {
    return await configService.fetchTransactionMethods();
  }
);

// Add transaction method
export const addTransactionMethod = createAsyncThunk(
  'finance/addTransactionMethod',
  async ({ name, type, category }) => {
    return await configService.addTransactionMethod(name, type, category);
  }
);

// Update transaction method
export const updateTransactionMethod = createAsyncThunk(
  'finance/updateTransactionMethod',
  async ({ key, data }) => {
    return await configService.updateTransactionMethod(key, data);
  }
);

// Delete transaction method
export const deleteTransactionMethod = createAsyncThunk(
  'finance/deleteTransactionMethod',
  async (key) => {
    return await configService.deleteTransactionMethod(key);
  }
);

// Initialize account balances
export const initializeAccounts = createAsyncThunk(
  'finance/initializeAccounts',
  async () => {
    await configService.initializeAccountBalances();
    return await configService.fetchAccountBalances();
  }
);

// Fetch account balances
export const fetchAccountBalances = createAsyncThunk(
  'finance/fetchAccountBalances',
  async () => {
    return await financeService.fetchAccountBalances();
  }
);

// Fetch transactions
export const fetchTransactions = createAsyncThunk(
  'finance/fetchTransactions',
  async () => {
    return await financeService.fetchTransactions();
  }
);

// Add transaction
export const addTransaction = createAsyncThunk(
  'finance/addTransaction',
  async (transactionData) => {
    return await financeService.addTransaction(transactionData);
  }
);

// Update transaction
export const updateTransaction = createAsyncThunk(
  'finance/updateTransaction',
  async ({ id, transaction }) => {
    return await financeService.updateTransaction(id, transaction);
  }
);

// Delete transaction
export const deleteTransaction = createAsyncThunk(
  'finance/deleteTransaction',
  async (id) => {
    await financeService.deleteTransaction(id);
    return id;
  }
);

const initialState = {
  transactions: [],
  accountBalances: {},
  transactionMethods: {},
  methodCategories: {},
  summary: {
    income: 0,
    expenses: 0,
    balance: 0
  },
  status: 'idle',
  error: null,
  loading: {
    transactions: false,
    methods: false,
    balances: false
  }
};

const financeSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    updateSummary: (state) => {
      const summary = state.transactions.reduce(
        (acc, transaction) => {
          const amount = parseFloat(transaction.amount);
          if (transaction.type === 'income') {
            acc.income += amount;
          } else {
            acc.expenses += amount;
          }
          acc.balance = acc.income - acc.expenses;
          return acc;
        },
        { income: 0, expenses: 0, balance: 0 }
      );
      state.summary = summary;
    },
    updateMethodCategories: (state, action) => {
      state.methodCategories = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch transaction methods
      .addCase(fetchTransactionMethods.pending, (state) => {
        state.loading.methods = true;
        state.error = null;
      })
      .addCase(fetchTransactionMethods.fulfilled, (state, action) => {
        state.loading.methods = false;
        state.transactionMethods = action.payload;
      })
      .addCase(fetchTransactionMethods.rejected, (state, action) => {
        state.loading.methods = false;
        state.error = action.error.message;
      })
      // Fetch account balances
      .addCase(fetchAccountBalances.pending, (state) => {
        state.loading.balances = true;
        state.error = null;
      })
      .addCase(fetchAccountBalances.fulfilled, (state, action) => {
        state.loading.balances = false;
        state.accountBalances = action.payload;
      })
      .addCase(fetchAccountBalances.rejected, (state, action) => {
        state.loading.balances = false;
        state.error = action.error.message;
      })
      // Fetch transactions
      .addCase(fetchTransactions.pending, (state) => {
        state.loading.transactions = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading.transactions = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading.transactions = false;
        state.error = action.error.message;
      })
      // Add transaction method
      .addCase(addTransactionMethod.fulfilled, (state, action) => {
        const { key, name, type, category } = action.payload;
        state.transactionMethods[key] = { name, type, category, isActive: true };
      })
      .addCase(addTransactionMethod.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Update transaction method
      .addCase(updateTransactionMethod.fulfilled, (state, action) => {
        const { key, name, type, category, isActive } = action.payload;
        state.transactionMethods[key] = { name, type, category, isActive };
      })
      .addCase(updateTransactionMethod.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Delete transaction method
      .addCase(deleteTransactionMethod.fulfilled, (state, action) => {
        delete state.transactionMethods[action.payload];
      })
      .addCase(deleteTransactionMethod.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Initialize accounts
      .addCase(initializeAccounts.fulfilled, (state, action) => {
        state.accountBalances = action.payload;
      })
      .addCase(initializeAccounts.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Add transaction
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.transactions.unshift(action.payload);
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Update transaction
      .addCase(updateTransaction.fulfilled, (state, action) => {
        const index = state.transactions.findIndex(t => t.id === action.payload.id);
        if (index !== -1) {
          state.transactions[index] = action.payload;
        }
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Delete transaction
      .addCase(deleteTransaction.fulfilled, (state, action) => {
        state.transactions = state.transactions.filter(t => t.id !== action.payload);
      })
      .addCase(deleteTransaction.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { updateSummary, updateMethodCategories } = financeSlice.actions;
export default financeSlice.reducer; 