import { db } from '../firebase';
import { collection, getDocs, setDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';

export const METHOD_TYPES = {
  'cash': 'Cash',
  'bank': 'Bank',
  'mobile': 'Mobile Banking',
  'credit': 'Credit Card'
};

export const METHOD_CATEGORIES = {
  'bank': ['Traditional Bank', 'Digital Bank', 'International Bank'],
  'mobile': ['Mobile Banking', 'Digital Wallet', 'Payment App'],
  'credit': ['Credit Card', 'Charge Card', 'Store Card'],
  'cash': ['Physical Cash', 'Petty Cash', 'Safe']
};

export const ACCOUNT_TAGS = {
  PERSONAL: 'Personal',
  BUSINESS: 'Business',
  SAVINGS: 'Savings',
  INVESTMENT: 'Investment',
  EMERGENCY: 'Emergency Fund',
  BILLS: 'Bills & Utilities',
  TRAVEL: 'Travel',
  OTHER: 'Other'
};

export const configService = {
  async fetchTransactionMethods() {
    try {
      const methodsRef = collection(db, 'transactionMethods');
      const snapshot = await getDocs(methodsRef);
      const methods = {};
      
      snapshot.forEach(doc => {
        methods[doc.id] = {
          name: doc.data().name,
          type: doc.data().type,
          isActive: doc.data().isActive
        };
      });
      
      return methods;
    } catch (error) {
      console.error('Error fetching transaction methods:', error);
      throw error;
    }
  },

  async validateMethodName(name) {
    try {
      const methods = await this.fetchTransactionMethods();
      return !Object.values(methods).some(
        method => method.name.toLowerCase() === name.toLowerCase()
      );
    } catch (error) {
      console.error('Error validating method name:', error);
      throw error;
    }
  },

  async addTransactionMethod(name, type, category) {
    try {
      // Validate name
      const isValid = await this.validateMethodName(name);
      if (!isValid) {
        throw new Error('A method with this name already exists');
      }

      // Validate type
      if (!METHOD_TYPES[type]) {
        throw new Error('Invalid method type');
      }

      // Validate category
      if (!METHOD_CATEGORIES[type]?.includes(category)) {
        throw new Error('Invalid category for the selected type');
      }

      const methodKey = name.toUpperCase().replace(/\s+/g, '_');
      await setDoc(doc(db, 'transactionMethods', methodKey), {
        name,
        type,
        category,
        isActive: true,
        createdAt: new Date()
      });

      // Also create corresponding account balance
      await setDoc(doc(db, 'accountBalances', name), {
        balance: 0,
        type,
        category,
        tags: [],
        description: '',
        lastUpdated: new Date()
      });

      return { key: methodKey, name, type, category };
    } catch (error) {
      console.error('Error adding transaction method:', error);
      throw error;
    }
  },

  async updateTransactionMethod(key, { name, type, category, isActive }) {
    try {
      // If name changed, validate new name
      const methods = await this.fetchTransactionMethods();
      const currentMethod = methods[key];
      
      if (name !== currentMethod.name) {
        const isValid = await this.validateMethodName(name);
        if (!isValid) {
          throw new Error('A method with this name already exists');
        }
      }

      // Validate type and category
      if (!METHOD_TYPES[type]) {
        throw new Error('Invalid method type');
      }

      if (!METHOD_CATEGORIES[type]?.includes(category)) {
        throw new Error('Invalid category for the selected type');
      }

      const methodRef = doc(db, 'transactionMethods', key);
      await updateDoc(methodRef, {
        name,
        type,
        category,
        isActive,
        updatedAt: new Date()
      });

      // Update account balance document if name changed
      if (name !== currentMethod.name) {
        const oldBalanceRef = doc(db, 'accountBalances', currentMethod.name);
        const newBalanceRef = doc(db, 'accountBalances', name);
        
        // Get current balance data
        const balanceDoc = await getDoc(oldBalanceRef);
        const balanceData = balanceDoc.data();
        
        // Create new balance document
        await setDoc(newBalanceRef, {
          ...balanceData,
          type,
          category,
          lastUpdated: new Date()
        });
        
        // Delete old balance document
        await deleteDoc(oldBalanceRef);
      }

      return { key, name, type, category, isActive };
    } catch (error) {
      console.error('Error updating transaction method:', error);
      throw error;
    }
  },

  async deleteTransactionMethod(key) {
    try {
      await deleteDoc(doc(db, 'transactionMethods', key));
      return key;
    } catch (error) {
      console.error('Error deleting transaction method:', error);
      throw error;
    }
  },

  async initializeAccountBalances() {
    try {
      const methods = await this.fetchTransactionMethods();
      const balancesRef = collection(db, 'accountBalances');
      const snapshot = await getDocs(balancesRef);
      
      if (snapshot.empty) {
        // Initialize balances for all methods
        for (const [key, method] of Object.entries(methods)) {
          if (method.isActive) {
            await setDoc(doc(db, 'accountBalances', method.name), {
              balance: 0,
              type: method.type,
              tags: [],
              description: '',
              lastUpdated: new Date()
            });
          }
        }
      }
    } catch (error) {
      console.error('Error initializing account balances:', error);
      throw error;
    }
  },

  async setInitialBalance(method, balance) {
    try {
      const balanceRef = doc(db, 'accountBalances', method);
      await updateDoc(balanceRef, {
        balance: parseFloat(balance),
        lastUpdated: new Date()
      });
      return { method, balance: parseFloat(balance) };
    } catch (error) {
      console.error('Error setting initial balance:', error);
      throw error;
    }
  },

  async addNewBankAccount(bankName, description = '', tags = []) {
    try {
      // Add as a transaction method
      await this.addTransactionMethod(bankName, 'bank', 'Traditional Bank');

      // Update account details
      await this.updateAccountDetails(bankName, { description, tags });

      return { method: bankName, type: 'bank', tags, description };
    } catch (error) {
      console.error('Error adding new bank account:', error);
      throw error;
    }
  },

  async updateAccountDetails(method, { tags, description }) {
    try {
      const accountRef = doc(db, 'accountBalances', method);
      await updateDoc(accountRef, {
        tags,
        description,
        lastUpdated: new Date()
      });
      return { method, tags, description };
    } catch (error) {
      console.error('Error updating account details:', error);
      throw error;
    }
  },

  async fetchAccountBalances() {
    try {
      const balancesRef = collection(db, 'accountBalances');
      const snapshot = await getDocs(balancesRef);
      const accounts = {};
      
      snapshot.forEach(doc => {
        const data = doc.data();
        accounts[doc.id] = {
          balance: data.balance,
          type: data.type,
          tags: data.tags || [],
          description: data.description || '',
          lastUpdated: data.lastUpdated
        };
      });
      
      return accounts;
    } catch (error) {
      console.error('Error fetching account balances:', error);
      throw error;
    }
  },

  async getAccountsByTag(tag) {
    try {
      const accounts = await this.fetchAccountBalances();
      return Object.entries(accounts)
        .filter(([_, account]) => account.tags.includes(tag))
        .reduce((acc, [method, account]) => {
          acc[method] = account;
          return acc;
        }, {});
    } catch (error) {
      console.error('Error fetching accounts by tag:', error);
      throw error;
    }
  }
}; 