import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTimeLogs,
  startTimeLog,
  stopTimeLog,
  addManualTimeLog,
  deleteTimeLog,
  updateDailySummary
} from '../store/slices/timeTrackingSlice';

const Timer = ({ activeLog, onStart, onStop }) => {
  const [elapsed, setElapsed] = useState(0);

  useEffect(() => {
    let interval;
    if (activeLog) {
      const startTime = new Date(activeLog.startTime).getTime();
      interval = setInterval(() => {
        setElapsed(Date.now() - startTime);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [activeLog]);

  const formatTime = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="text-center">
        <div className="text-4xl font-mono mb-4">
          {activeLog ? formatTime(elapsed) : '00:00:00'}
        </div>
        {!activeLog ? (
          <div>
            <input
              type="text"
              placeholder="What are you working on?"
              className="input mb-4"
              id="activity-input"
            />
            <button
              onClick={() => onStart(document.getElementById('activity-input').value)}
              className="btn btn-primary w-full"
            >
              Start Timer
            </button>
          </div>
        ) : (
          <button
            onClick={onStop}
            className="btn bg-red-600 text-white hover:bg-red-700 w-full"
          >
            Stop Timer
          </button>
        )}
      </div>
    </div>
  );
};

const ManualEntryForm = ({ onSubmit }) => {
  const [values, setValues] = useState({
    activity: '',
    startTime: '',
    endTime: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(values);
    setValues({ activity: '', startTime: '', endTime: '' });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Activity</label>
        <input
          type="text"
          value={values.activity}
          onChange={(e) => setValues({ ...values, activity: e.target.value })}
          className="input mt-1"
          required
        />
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Start Time</label>
          <input
            type="datetime-local"
            value={values.startTime}
            onChange={(e) => setValues({ ...values, startTime: e.target.value })}
            className="input mt-1"
            required
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700">End Time</label>
          <input
            type="datetime-local"
            value={values.endTime}
            onChange={(e) => setValues({ ...values, endTime: e.target.value })}
            className="input mt-1"
            required
          />
        </div>
      </div>

      <button type="submit" className="btn btn-primary">
        Add Time Log
      </button>
    </form>
  );
};

const TimeLogList = ({ logs, onDelete }) => {
  const formatDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = endTime ? new Date(endTime) : new Date();
    const diff = end - start;
    
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activity</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Time</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Time</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {logs.map((log) => (
            <tr key={log.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {log.activity}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(log.startTime).toLocaleString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {log.endTime ? new Date(log.endTime).toLocaleString() : 'Running'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDuration(log.startTime, log.endTime)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  onClick={() => onDelete(log.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DailySummary = ({ summary }) => {
  const formatTime = (ms) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Daily Summary</h2>
      {Object.entries(summary).map(([date, data]) => (
        <div key={date} className="mb-6 last:mb-0">
          <h3 className="text-sm font-medium text-gray-700 mb-2">{date}</h3>
          <p className="text-2xl font-semibold text-gray-900 mb-3">
            {formatTime(data.totalTime)}
          </p>
          <div className="space-y-2">
            {Object.entries(data.activities).map(([activity, duration]) => (
              <div key={activity} className="flex justify-between text-sm">
                <span className="text-gray-600">{activity}</span>
                <span className="text-gray-900">{formatTime(duration)}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const TimeTracking = () => {
  const dispatch = useDispatch();
  const { logs, activeLog, dailySummary } = useSelector((state) => state.timeTracking);

  useEffect(() => {
    dispatch(fetchTimeLogs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateDailySummary());
  }, [logs, dispatch]);

  const handleStartTimer = async (activity) => {
    if (!activity.trim()) return;
    await dispatch(startTimeLog({ activity }));
  };

  const handleStopTimer = async () => {
    if (activeLog) {
      await dispatch(stopTimeLog({ id: activeLog.id }));
    }
  };

  const handleManualEntry = async (data) => {
    await dispatch(addManualTimeLog({
      ...data,
      startTime: new Date(data.startTime).toISOString(),
      endTime: new Date(data.endTime).toISOString()
    }));
  };

  const handleDeleteLog = async (id) => {
    if (window.confirm('Are you sure you want to delete this time log?')) {
      await dispatch(deleteTimeLog(id));
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Time Tracking</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2">
          <Timer
            activeLog={activeLog}
            onStart={handleStartTimer}
            onStop={handleStopTimer}
          />
          
          <div className="mt-6 bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Manual Time Entry</h2>
            <ManualEntryForm onSubmit={handleManualEntry} />
          </div>
        </div>

        <div>
          <DailySummary summary={dailySummary} />
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Time Logs</h2>
        </div>
        <TimeLogList logs={logs} onDelete={handleDeleteLog} />
      </div>
    </div>
  );
};

export default TimeTracking; 