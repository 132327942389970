import { configureStore } from '@reduxjs/toolkit';
import notesReducer from './slices/notesSlice';
import financeReducer from './slices/financeSlice';
import timeTrackingReducer from './slices/timeTrackingSlice';

export const store = configureStore({
  reducer: {
    notes: notesReducer,
    finance: financeReducer,
    timeTracking: timeTrackingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['finance/addTransaction/fulfilled'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['finance.transactions.timestamp'],
      },
    }),
}); 