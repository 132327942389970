import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotes, createNote, updateNote, deleteNote } from '../store/slices/notesSlice';

const NoteForm = ({ onSubmit, initialValues = { title: '', content: '', tags: '' }, isEditing }) => {
  const [values, setValues] = useState(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...values,
      tags: values.tags.split(',').map(tag => tag.trim()).filter(Boolean)
    });
    if (!isEditing) {
      setValues({ title: '', content: '', tags: '' });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
          className="input mt-1"
          required
        />
      </div>
      
      <div>
        <label htmlFor="content" className="block text-sm font-medium text-gray-700">
          Content
        </label>
        <textarea
          id="content"
          value={values.content}
          onChange={(e) => setValues({ ...values, content: e.target.value })}
          rows={4}
          className="input mt-1"
          required
        />
      </div>
      
      <div>
        <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
          Tags (comma-separated)
        </label>
        <input
          type="text"
          id="tags"
          value={values.tags}
          onChange={(e) => setValues({ ...values, tags: e.target.value })}
          className="input mt-1"
          placeholder="work, personal, important"
        />
      </div>
      
      <button type="submit" className="btn btn-primary">
        {isEditing ? 'Update Note' : 'Add Note'}
      </button>
    </form>
  );
};

const NoteCard = ({ note, onEdit, onDelete }) => (
  <div className="bg-white shadow rounded-lg p-6">
    <div className="flex justify-between items-start">
      <h3 className="text-lg font-medium text-gray-900">{note.title}</h3>
      <div className="flex space-x-2">
        <button
          onClick={() => onEdit(note)}
          className="text-gray-400 hover:text-gray-500"
        >
          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>
        </button>
        <button
          onClick={() => onDelete(note.id)}
          className="text-gray-400 hover:text-red-500"
        >
          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      </div>
    </div>
    <p className="mt-2 text-gray-600">{note.content}</p>
    {note.tags && note.tags.length > 0 && (
      <div className="mt-4 flex flex-wrap gap-2">
        {note.tags.map((tag, index) => (
          <span
            key={index}
            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
          >
            {tag}
          </span>
        ))}
      </div>
    )}
  </div>
);

const Notes = () => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.items);
  const status = useSelector((state) => state.notes.status);
  const [editingNote, setEditingNote] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchNotes());
    }
  }, [status, dispatch]);

  const handleCreateNote = async (noteData) => {
    await dispatch(createNote(noteData));
  };

  const handleUpdateNote = async (noteData) => {
    await dispatch(updateNote({ id: editingNote.id, note: noteData }));
    setEditingNote(null);
  };

  const handleDeleteNote = async (id) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      await dispatch(deleteNote(id));
    }
  };

  const filteredNotes = notes.filter(note => {
    const matchesSearch = note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTag = !selectedTag || (note.tags && note.tags.includes(selectedTag));
    return matchesSearch && matchesTag;
  });

  const allTags = [...new Set(notes.flatMap(note => note.tags || []))];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Notes</h1>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search notes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input max-w-xs"
          />
          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="input max-w-xs"
          >
            <option value="">All Tags</option>
            {allTags.map(tag => (
              <option key={tag} value={tag}>{tag}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          {editingNote ? 'Edit Note' : 'Create New Note'}
        </h2>
        <NoteForm
          onSubmit={editingNote ? handleUpdateNote : handleCreateNote}
          initialValues={editingNote || undefined}
          isEditing={!!editingNote}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {filteredNotes.map(note => (
          <NoteCard
            key={note.id}
            note={note}
            onEdit={setEditingNote}
            onDelete={handleDeleteNote}
          />
        ))}
      </div>
    </div>
  );
};

export default Notes;